import React from 'react';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import loadable from '@loadable/component';
import queryString from 'query-string';
import Cookies from 'js-cookie';

import logo from 'src/assets/images/logo.svg';
// import logo from 'src/assets/images/logo-xmas.svg';
import logoDark from 'src/assets/images/logo-dark.svg';
// import logoDark from 'src/assets/images/logo-dark-xmas.svg';
import { URL } from 'src/resources/constants/url';
import iconProfile from 'src/assets/images/icon-profile.svg';
import iconProfileGreyDark from 'src/assets/images/icon-profile-grey-dark.svg';
import { LabelLang } from 'src/lang/components';
import { TOP_BANNER_HEIGHT } from 'src/components/topBanner';
import { checkIsUSIp, roundNumCollateral } from 'src/utils/common';
import AppIcon from 'src/components/appIcon';
import { COOKIE_SIGNUP_BORROW } from 'src/resources/constants/app';

import Menu from './menu';
import AppPath from './appPath';
import {
  ABOUT_MENUS,
  AFFILIATES,
  COMMUNITY_MENUS, MEMBERSHIP_NFT_MENUS,
  NEW_BORROW_EXTRA_MENUS,
  NEW_INVEST_EXTRA_MENUS,
  NEW_SPENDING_EXTRA_MENUS,
} from './constant';
import {
  AffiliateAssociationStyled,
  ContainerStyled,
  CreateAccountStyled,
  HeaderContainerStyled,
  HeaderStyled,
  InvestBorrowMenuStyled,
  MagicBlockStyled,
  MenuItemStyled,
} from './styled';
import AffiliateBanner from './affiliateBanner';
import loginModal from 'src/components/loginPopup';
import { closeModal, openModal } from 'src/components/modal';
import withCryptoInterest from 'src/hocs/hocCryptoInterest';

const UserNotification = loadable(() => import('src/components/userNotification'));
const UserLogin = loadable(() => import('src/components/userLogin'));
const ExtraMenu = loadable(() => import('./extraMenu'));
const HoverMenu = loadable(() => import('./hoverMenu'));
const DownloadAppMenu = loadable(() => import('./downloadApp'));

const HIDE_APP_PATHS = [
  URL.FEE_AND_SERVICES_TIMES
];

const LIGHT_HEADER_PAGES = [
  URL.AGREEMENTS,
  URL.FEE_AND_SERVICES_TIMES,
  URL.ACTIVATE_ACCOUNT,
  URL.EMAIL_CONFIRM_RESERVE,
  URL.DEBIT_CARD,
  URL.DEBIT_CARD_STATEMENTS,
  URL.DEBIT_CARD_TRANSACTIONS,
  URL.DEBIT_CARD_PHYSICAL,
  URL.DEBIT_CARD_REWARD,
  URL.CREDIT_PAYMENT,
  URL.USER_FORGET_PASSWORD,
  URL.USER_FORGET_PASSWORD_FINISH,
  URL.CRYPTO_LEND_TO_EARN,
];

class Header extends React.PureComponent {
  constructor(){
    super();
    this.state = {
      scrolled: false,
      isHoverMenuShowed: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScroll);
  }
  listenScroll = () => {
    this.setState({
      scrolled: (window.pageYOffset || 0) > 0
    });
  };
  formatNumber = num => {
    return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
      Number(num),
    );
  };

  onHoverMenuShow = (showed) => {
    this.setState({ isHoverMenuShowed: showed });
  };

  // onClickLogin = () => {
  //   if (this.props.location.pathname === URL.AIRDROP_WHEEL) ga(events.category.airdropPrizewheel, events.action.air_login_click);
  // };

  // onClickSignUp = () => {
  //   if (this.props.location.pathname === URL.AIRDROP_WHEEL) ga(events.category.airdropPrizewheel, events.action.air_login_click);
  // };

  handleClickCreateAccount = () => {
    const { history, location, openModal, closeModal } = this.props;
    const isSignUpBorrow = Cookies.get(COOKIE_SIGNUP_BORROW);

    if (
      location.pathname.includes(URL.CRYPTO_LEND_SIGN_UP)
      || location.pathname.includes(URL.CRYPTO_LEND_TO_EARN)
    ) {
      const el = document.getElementById('registerForm');
      if (el) el.scrollIntoView();
      return;
    }

    if (location.pathname.includes(URL.FLEX_CRYPTO)) {
      return loginModal(
        { openModal, closeModal },
        () => { },
        { view: 'REGISTER' }
      );
    }
    history.push(isSignUpBorrow ? URL.USER_SIGN_UP_BORROW : URL.USER_SIGN_UP);
  };

  render() {
    const { scrolled, isHoverMenuShowed } = this.state;
    const { isUSIp, isAuth, location, headerTheme, showSignUpHeader, showBorrowHeader, isLockRedirect, topBannerType, cryptoLendRate, flexCollateralsList, affiliateInfo, numCollateral, maxStakingRate, cryptoInterestProps } = this.props;
    const params = queryString.parse(location.search.replace('?', ''));
    let isLight = true;
    const isSignIn = [URL.USER_SIGN_IN, URL.THANK_YOU, URL.UNSUBSCRIBE_EMAIL, URL.USER_SIGN_IN_MONEY_MADE].includes(location.pathname);
    const isBudgeting = [
      URL.PERSONAL_BUDGET_DASHBOARD,
      URL.PERSONAL_BUDGET_TRANSACTIONS,
      URL.PERSONAL_BUDGET_LINK_BANKS,
      URL.PERSONAL_BUDGET_BUDGETS,
      URL.FORUM_CHAT,
      URL.FORUM_CHAT_CATEGORY
    ].includes(location.pathname);
    const isStaticPage = LIGHT_HEADER_PAGES.includes(location.pathname);
    let isPro = [URL.CONSTANT_TOKEN, URL.REDEEM_MCT].includes(location.pathname);
    if (([URL.CRYPTO_LEND_TO_EARN].includes(location.pathname) && params.v === 'c') || ([URL.USER_SIGN_UP_BORROW].includes(location.pathname) && params.v === 'c')) {
      isPro = true;
    }

    if (([URL.INVEST, URL.CRYPTO_LOAN, URL.DEPOSIT_FIAT].includes(location.pathname) && headerTheme) || isSignIn || headerTheme === 'light') {
      isLight = false;
    }
    const isDark = (isLight && !scrolled && !isBudgeting && !isStaticPage ) || isPro;

    const affiliateByUrl = AFFILIATES.find(aff => location.pathname.includes(aff.path));
    const affiliateData = affiliateInfo || affiliateByUrl;
    const isSignUpAffiliate = (!!affiliateInfo || params?.affiliate) && location.pathname !== '/' && location.pathname.includes(URL.USER_SIGN_UP);
    const affiliateBorrow = affiliateInfo?.affiliate_type === 'borrow' || params?.ads === 'borrow-signup';
    const affiliateCryptoLend = affiliateInfo?.affiliate_type === 'cryptolend';

    let topValue = 0;
    if (location?.pathname?.toLowerCase() !== URL.QANDA?.toLowerCase()) {
      topValue += TOP_BANNER_HEIGHT; // always show suggest top banner if logged in
    }
    if(!scrolled) {
      // if (topBannerType) {
      //   topValue += TOP_BANNER_HEIGHT; // always show suggest top banner if logged in
      // }
      if(isSignUpAffiliate && !affiliateBorrow && !affiliateCryptoLend) {
        topValue += 40;
      }
    }

    const [cryptoLendRateStable] = cryptoInterestProps;

    // console.log('cryptoInterestProps', cryptoInterestProps);

    return (<>
      {affiliateInfo && !affiliateBorrow && !affiliateCryptoLend && (<AffiliateBanner affiliateInfo={affiliateData} />)}
      <HeaderContainerStyled
        isPro={isPro}
        scrolled={scrolled}
        topSpacing={topValue}
        isBoxShadow={isBudgeting}
        isAffiliate={isSignUpAffiliate}
      >
        <HeaderStyled>
          <ContainerStyled isLockRedirect={isLockRedirect}>
            <div>
              <Link to={URL.HOME} name="logo">
                <AppIcon>
                  <img src={isDark ? logoDark : logo} alt={isDark ? 'MyConstant alternative logo' : 'MyConstant official logo'} />
                </AppIcon>
              </Link>
            </div>
            <InvestBorrowMenuStyled className={`${affiliateByUrl || affiliateBorrow ? 'isAffiliateBorrow' : ''}`}>
              <Menu
                title={<LabelLang id="headerBar.save" />}
                isDark={isDark}
                isPro={isPro}
                onShow={this.onHoverMenuShow}
                titleClassName={cx('titleMenuExtra', isPro ? 'proMenu' : '', scrolled ? 'titleMenuExtraScrolled' : '')}
                openClassName="openMenuCenter"
                isModal
              >
                <ExtraMenu isAuth={isAuth} isPro={isPro} data={NEW_INVEST_EXTRA_MENUS({cryptoLendRate, flexCollateralsList, cryptoLendRateStable, numCollateral, maxStakingRate})} scrolled={scrolled} />
              </Menu>
              <Menu
                title={<LabelLang id="headerBar.borrow" />}
                isDark={isDark}
                isPro={isPro}
                onShow={this.onHoverMenuShow}
                titleClassName={cx('titleMenuExtra', isPro ? 'proMenu' : '', scrolled ? 'titleMenuExtraScrolled' : '')}
                openClassName="openMenuCenter"
                isModal
              >
                <ExtraMenu isAuth={isAuth} isPro={isPro} data={NEW_BORROW_EXTRA_MENUS} scrolled={scrolled} />
              </Menu>
              <Menu
                title={<LabelLang id="headerBar.spending" />}
                isDark={isDark}
                isPro={isPro}
                onShow={this.onHoverMenuShow}
                titleClassName={cx('titleMenuExtra', isPro ? 'proMenu' : '', scrolled ? 'titleMenuExtraScrolled' : '')}
                openClassName="openMenuCenter"
                isModal
              >
                <ExtraMenu isAuth={isAuth} isPro={isPro} data={NEW_SPENDING_EXTRA_MENUS} scrolled={scrolled} />
              </Menu>
            </InvestBorrowMenuStyled>
            <div>
              <Menu title="Membership & NFT" proButton isDark={isDark} isPro={isPro} onShow={this.onHoverMenuShow} openClassName="openMenuCommunity" isModal>
                <HoverMenu data={MEMBERSHIP_NFT_MENUS} isModal />
              </Menu>
              {/* <Menu title={<LabelLang id="headerBar.communicate" />} proButton isDark={isDark} isPro={isPro} onShow={this.onHoverMenuShow} openClassName="openMenuCommunity" isModal>
                <HoverMenu data={COMMUNITY_MENUS} isModal />
              </Menu> */}
              {/* {!affiliateInfo && (
                <MenuItemStyled isPro={isPro} isDark={isDark} to={URL.PRO_LENDING}>
                  <LabelLang id="headerBar.landingPro" />
                </MenuItemStyled>
              )} */}
              {isAuth && (
                <MenuItemStyled isPro={isPro} isDark={isDark} to={URL.ACCOUNTS}>
                  <LabelLang id="headerBar.yourSaving" />
                </MenuItemStyled>
              )}
              {!affiliateByUrl && !affiliateBorrow && (
                <Menu title={<LabelLang id="headerBar.about" />} isDark={isDark} isPro={isPro} onShow={this.onHoverMenuShow} openClassName="openMenuAbout" isModal>
                  <HoverMenu data={ABOUT_MENUS.filter(e => e.isUS ? isUSIp : true)} isModal />
                </Menu>
              )}
              {/* {!affiliateByUrl && !affiliateBorrow && (
                <Menu title="Download" isDark={isDark} isPro={isPro} hideArrow onShow={this.onHoverMenuShow} openClassName="openMenuAbout" isModal>
                  <DownloadAppMenu isModal />
                </Menu>
              )} */}
              {isAuth && (
                <UserNotification className="user" bgHeader isDark={isDark} isHoverMenuShowed={isHoverMenuShowed} isModal />
              )}
              {isAuth ? (
                <Menu
                  title={<img alt="" src={isDark ? iconProfileGreyDark : iconProfile} />}
                  isDark={isDark}
                  isPro={isPro}
                  onShow={this.onHoverMenuShow}
                  titleClassName="titleMenuIcon"
                  openClassName="openUserLogin"
                  isModal
                >
                  <UserLogin isModal />
                </Menu>
              ) : (
                <>
                  {
                    (affiliateByUrl || affiliateBorrow) ? (
                      <span>
                        <AffiliateAssociationStyled isDark={isDark} onClick={() => {window.open(affiliateData?.website_url, '_blank')}}>
                          <div className="associationWith">In Association With </div>
                          <div className="affiliateLogo">{affiliateData?.logo_url ? <img alt="" src={affiliateData?.logo_url} /> : affiliateData?.name }</div>
                        </AffiliateAssociationStyled>
                      </span>
                    ) : (
                      <span>
                        <MenuItemStyled isPro={isPro} isDark={isDark} to={URL.USER_SIGN_IN}>
                          <LabelLang id="headerBar.log_in" />
                        </MenuItemStyled>
                        <CreateAccountStyled isPro={isPro} isDark={isDark} onClick={this.handleClickCreateAccount}>
                          <LabelLang id="headerBar.createAccount" />
                        </CreateAccountStyled>
                      </span>
                    )
                  }
                </>
              )}
            </div>
          </ContainerStyled>
        </HeaderStyled>
      </HeaderContainerStyled>
      <MagicBlockStyled />
      {!(HIDE_APP_PATHS.includes(location.pathname) || location.pathname.includes(URL.ME)) && (
        <AppPath />
      )}
    </>);
  }
}
const mapState = state => ({
  isAuth: !!state.auth.token,
  headerTheme: state.app.headerTheme,
  verifiedLevel: state.auth.profile?.verifiedLevel,
  primetrustContactID: state.auth.profile?.primetrustContactID,
  profile: state.auth.profile,
  email: state.auth.profile?.email,
  isDefaultIp: state?.app?.ipInfo?.is_default,
  showSignUpHeader: state?.app?.showSignUpHeader,
  showBorrowHeader: state?.app?.showBorrowHeader,
  showPromotionHeader: state?.app?.showPromotionHeader,
  isLockRedirect: state.app.isLockRedirect,
  isUSIp: checkIsUSIp(state),
  topBannerType: state.topBanner.type,
  cryptoLendRate: state.app.contentVariables.crypto_lend_rate,
  cryptoLendRateStable: state.app.contentVariables.crypto_lend_stablecoin_rate,
  affiliateInfo: state.register?.affiliateInfo,
  flexCollateralsList: state.app.flexCollaterals,
  numCollateral: roundNumCollateral(state.app.contentVariables.cryptos_as_collateral_number),
  maxStakingRate: state.app.contentVariables.mct_staking_max_rate,
});

export default compose(
  connect(mapState, { openModal, closeModal }),
  withRouter,
)(withCryptoInterest(Header));
