import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import moment from 'moment-timezone/moment-timezone';
import { BigNumber } from 'bignumber.js';
import CountUp from 'react-countup';

import LabelLang from 'src/lang/components/LabelLang';
import { formatCurrencyByLocale } from 'src/utils/format/currency';
import MyPopover from 'src/components/styled/MyPopover';
import { DAYS_PER_MONTH, ONE_YEAR_SECONDS } from 'src/resources/constants/app';
import { fromNowRepleace } from 'src/utils/timeString';
import Loading from 'src/components/loading';
import { getStatisticInvestment } from 'src/screens/statistic/action';

import separator from './img/separator.svg';
import styles from './styles.scss';
import { getMatchedBookings, getMatchStats } from './action';

const getIntlKey = name => `proLending.matchStats.${name}`;

const StatisticWidget = props => {
  const { className, showVolumn } = props;
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [bookings, setBookings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statisticData, setStatisticData] = useState({});
  const [isLoadingStatisticData, setIsLoadingStatisticData] = useState(false);
  const [oldTotalInterestAmount, setOldTotalInterestAmount] = useState(0);
  const [totalInterestAmount, setTotalInterestAmount] = useState(0);
  const [flexInterest, setFlexInterest] = useState(0);

  const timer = useRef();

  useEffect(() => {
    getMatchedData();
    getStatisticData();
    dispatch(getMatchedBookings()).then(res => {
      setBookings(res?.length > 15 ? res.slice(0, 15) : res);
    });

    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if(statisticData) {
      initTimer();
      updateBalanceInterest();
    }
  }, [statisticData]);

  useEffect(() => {
    updateBalanceInterest();
  }, [flexInterest]);

  const initTimer = () => {
    clearTimer();
    timer.current = setInterval(calculateBalanceInterest, 3000);
  };

  const clearTimer = () => {
    if (timer) clearInterval(timer.current);
  };

  const getMatchedData = () => {
    setIsLoading(true);
    dispatch(getMatchStats()).then(res => {
      setData(res);
    }).catch(err => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getStatisticData = () => {
    setIsLoadingStatisticData(true);
    dispatch(getStatisticInvestment()).then(obj => {
      setStatisticData(obj);
    }).catch(err => console.log(err))
      .finally(() => {
        setIsLoadingStatisticData(false);
      });
  };

  const updateBalanceInterest = () => {
    setOldTotalInterestAmount(totalInterestAmount);
    const interestAmount = Number(statisticData?.totalInterestAmount || 0);
    const newInterestAmount = Number(statisticData?.newTotalInterestAmount || 0);
    const newTotalInterestAmount = new BigNumber(flexInterest).plus(interestAmount).plus(newInterestAmount).decimalPlaces(3, BigNumber.ROUND_DOWN).toNumber() || 0;
    setTotalInterestAmount(newTotalInterestAmount);
  };

  const calculateBalanceInterest = () => {
    const { totalBalance, updatedAt } = statisticData;
    const currentInterestRate = 4;

    const diffTime = moment.utc().diff(updatedAt, 'second') || 0;
    const rateYear = new BigNumber(currentInterestRate).dividedBy(new BigNumber(100)).dividedBy(new BigNumber(ONE_YEAR_SECONDS));
    const rate = new BigNumber(1).plus(rateYear);
    const interestBalance = new BigNumber(totalBalance).multipliedBy(Math.pow(rate.toNumber(), diffTime)).minus(totalBalance);

    setFlexInterest(interestBalance);
  };

  if (!data && !statisticData) {
    return null;
  }

  const getStyleTrend = (oldValue, newValue) => {
    let result = styles.equal;
    if (Number(newValue) > Number(oldValue)) {
      result = styles.up;
    } else if (Number(newValue) < Number(oldValue)) {
      result = styles.down;
    }

    return result;
  };

  const { maxRate = '0', volume = '0', lastMaxRate = '0', lastVolume = '0', maxRateDetail = {}} = data;

  return (
    <div style={{height: '69px'}}></div>
  );

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.dataItem}><div className={styles.live}>LIVE</div></div>
      {maxRate && (
        <MyPopover
          className={styles.maxRateOrderDetailPopup}
          placement="bottom"
          content={(
            <div className={styles.maxRateDetail}>
              <div>
                <label>Amount (USD)</label>
                <div>{formatCurrencyByLocale(maxRateDetail?.amount || '0')}</div>
              </div>
              <div>
                <label>Interest (APR)</label>
                <div>{formatCurrencyByLocale(maxRateDetail?.rate || '0')}%</div>
              </div>
              <div>
                <label>Term (Days)</label>
                <div>{(Number(maxRateDetail?.term) * DAYS_PER_MONTH).toFixed(0)} days</div>
              </div>
              <div>
                <label>Market</label>
                <div>{maxRateDetail?.type === 'void' ? 'Secondary' : 'Primary'}</div>
              </div>
              {/* <div>
                <label>User type</label>
                <div><LabelLang id={`memberShip.memberShipLevel.level${maxRateDetail?.membership}`} /></div>
              </div> */}
              <div className={styles.time}>
                <label>Time</label>
                <div>{moment(maxRateDetail?.createdAt).format('MMM DD, HH:mm')}</div>
              </div>
            </div>
        )}
        >
          <div className={cx(styles.dataItem, styles.rate)}>
            {isLoading ?
              <Loading name='loading' dark={false} /> : (
                <div>
                  <span className={cx(styles.status, getStyleTrend(lastMaxRate, maxRate))} />
                  <span className={styles.interest}>{formatCurrencyByLocale(maxRate)}%</span>
                  &nbsp;<small>{formatCurrencyByLocale(lastMaxRate)}%</small>
                </div>
              )
            }
            <label>
              <LabelLang id={getIntlKey('high')} />
            </label>
          </div>
        </MyPopover>
      )}
      <div className={styles.dataItem}>
        <img alt="" src={separator} className={styles.separator} />
      </div>
      <MyPopover
        className={styles.tradeHistoryPopup}
        placement="bottom"
        content={(
          <div className={styles.tradeHistory}>
            <div>
              <label>Amount (USD)</label>
            </div>
            <div>
              <label>Interest (APR)</label>
            </div>
            <div>
              <label>Term (Days)</label>
            </div>
            {/* <div>
              <label>User type</label>
            </div> */}
            <div>
              <label>Time ago</label>
            </div>
            {bookings && bookings.map(item => (
              <React.Fragment key={item}>
                <div>
                  <div>{formatCurrencyByLocale(item.balance || item.amount)}</div>
                </div>
                <div>
                  <div>{Number(item.rate).toFixed(2)}%</div>
                </div>
                <div>
                  <div>{item.days}</div>
                </div>
                {/* <div>
                  <LabelLang id={`memberShip.memberShipLevel.level${item?.membership}`} />
                </div> */}
                <div>
                  <div className={styles.time}>{fromNowRepleace(moment(item.createdAt).fromNow(true))}</div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      >
        {showVolumn
          ? (
            <div className={styles.dataItem}>
              {isLoading ?
                <Loading name='loading' dark={false} /> : (
                  <div>
                    <span className={cx(styles.status, getStyleTrend(lastVolume, volume))} />
                    <span className={styles.amount}>${formatCurrencyByLocale(volume, 0)}</span>
                    &nbsp;<small> ${formatCurrencyByLocale(lastVolume, 0)}</small>
                  </div>
                )
              }
              <label>
                <LabelLang id={getIntlKey('volume')} />
              </label>
            </div>
          ) : (
            <div className={cx(styles.dataItem, styles.interest)}>
              {isLoadingStatisticData
                ? <Loading name='loading' dark={false} />
                : (
                  <div>
                    <div className={cx(styles.status, getStyleTrend(oldTotalInterestAmount, totalInterestAmount))} />
                    <div className={styles.amount}><CountUp prefix='$' separator=',' start={oldTotalInterestAmount} end={totalInterestAmount} decimals={3} duration={3} useEasing={false} /></div>
                    &nbsp;<small> ${formatCurrencyByLocale(lastVolume, 0)}</small>
                  </div>
                )
              }
              <label>
                <LabelLang id={getIntlKey('totalInterest')} />
                <MyPopover desc="statistic.stats.totalInterestDesc" size="sm" />
              </label>
            </div>
          )
        }
      </MyPopover>
    </div>
  );
};

export default StatisticWidget;
