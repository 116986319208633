import { makeRequest, SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';

export const getMatchStats = () => makeRequest({
  url: API_URL.PRO_LENDING.MATCH_STATS,
  service: SERVICES.SAVING,
  auth: 'ehPETnmaUC9HeLq2FhW4NZZVZQFRLbmTe8JB45mVr3KcFXWQ',
  normalize: res => camelizeKeys(res),
});

export const getMatchedBookings = () => makeRequest({
  url: `${API_URL.PRO_LENDING.MATCHED_BOOKINGS}`,
  service: SERVICES.SAVING,
  withAuth: false,
  normalize: res => camelizeKeys(res),
});