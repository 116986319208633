import makeRequest, { SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';

export const getStatisticInvestment = () => makeRequest({
  type: 'STATISTIC_INVESMENT',
  url: API_URL.STATISTIC.STATISTIC_INVESMENT,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getStatisticInvestmentByMonth = () => makeRequest({
  type: 'STATISTIC_INVESMENT_BY_MONTH',
  url: API_URL.STATISTIC.STATISTIC_INVESMENT_BY_MONTH,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getStatisticInvestmentByCountry = () => makeRequest({
  type: 'STATISTIC_INVESMENT_BY_COUNTRY',
  url: API_URL.STATISTIC.STATISTIC_INVESMENT_BY_COUNTRY,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});
