import styled, { css } from 'styled-components';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HEADER_HEIGHT } from 'src/resources/constants/app';

export const MagicBlockStyled = styled.div`
  height:  ${HEADER_HEIGHT}px;
`;

export const HeaderContainerStyled = styled.header`
  position: fixed;
  z-index: 1039;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  top: ${props => props.topSpacing + 'px'};
  background: transparent;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  border-bottom: ${props => props.isBoxShadow ? '1px solid #eee' : 'none'};
  border-top: ${props => props.isBoxShadow ? '1px solid #eee' : 'none'};
  %main-text {
    color:  #141518;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.17px;
  }

  ${props => props.scrolled && css`
    background-color: white;
    border-bottom: 1px solid #f6f6f6;
    box-shadow: 0 2px 20px 0 rgba(0,3,110,0.04);
  `}

  ${props => props.isPro && css`
    border-bottom: 1px solid #1e1e1e;
    background-color: #1e1e1e;
  `}
`;

export const HeaderStyled = styled.div`
  flex: 1;
  margin-bottom: 0px;
  padding: 0 40px;
`;

export const ContainerStyled = styled(Container)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  > div:last-child {
    justify-content: flex-end;
  }
  a {
    &[name=logo] {
      margin: 0 0 6px 0;
    }
  }
  .titleMenuExtra {
    &:hover {
      background-image: linear-gradient(140deg, rgba(151, 151, 151, 0.02), rgba(255, 255, 255, 0.23));
      border-radius: 20.5px;
      &.proLending {
        color: #FFFFFF;
        background-image: linear-gradient(149deg, rgba(34, 34, 34, 0.7), rgba(78, 78, 78, 0.37)) !important;
      }
      &.titleMenuExtraScrolled {
        color: inherit;
      }
    }
  }

  .titleMenuIcon {
    min-width: unset;
  }

  .openUserLogin {
    padding-top: 13px;
    -webkit-transform: translateX(-83%);
    transform: translateX(-83%);
  }
  .openMenuAbout {
    -webkit-transform: translateX(calc(-50% + 45px));
    transform: translateX(calc(-50% + 45px));
  }

  ${props => props.isLockRedirect && css`
    pointer-events: none;
    > div:not(:first-child) {
      visibility: hidden;
    }
  `}
`;

export const InvestBorrowMenuStyled = styled.div`
  display: flex;
  margin-right: -10%;
  .openMenuCenter {
    left: 0;
  }
  
  &.isAffiliateBorrow {
    margin-right: -10%;
  }
`;

export const MenuItemStyled = styled(Link)`
  font-size: 15px;
  margin: 0 10px;
  position:relative;
  &, &:hover, &:active {
    font-weight: 400;
    color: ${props => (props.isPro || props.isDark) ? 'white' : '#000'};
    text-decoration: none;
  }
  ${props => props.proButton && css`
    &, &:hover {
      font-weight: 500;
      color: #f5a623 !important;
    }
  `}
  
  .newText {
    position: absolute;
    color: #f5a623;
    font-size: 10px;
    top: -12px;
    left: -15px;
  }
  
  .newFeatureBg {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 20px;
    top: -25px;
  }
`;

export const CreateAccountStyled = styled(Button)`
  border-radius: 20px;
  margin-left: 20px;
  width: 150px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  font-size: 15px;

  &, &:hover, &:active, &:focus {
    background-image: linear-gradient(175deg, #359E84 0%, #2B9990 100%);
    outline: none !important;
    border-color: unset !important;
    box-shadow: none !important;
  }

  ${props => props.isPro && css`
    background-image: linear-gradient(135deg, #323538 0%, #232323 100%);
  `}
`;

export const AffiliateAssociationStyled = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  
  img {
    height: 44px;
    max-height: 44px;
  }
  
  .associationWith {
    font-size: 15px;
    margin: 0 10px;
    position:relative;
    &, &:hover, &:active {
      font-weight: 400;
      color: ${props => props.isDark ? 'white' : '#000'};
    }
  }

  .affiliateLogo {
    font-weight: 700;
    font-size: 18px;
    color: ${props => props.isDark ? 'white' : '#000'};
  }
`;
