import React, { PureComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { modalMenuOff, modalMenuOn } from './constant';

const ExtraMenuStyle = styled.div`
  color: ${props => props.isDark ? 'white' : 'black'};
  font-size: inherit;
  
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    min-width: 100px;  
    font-size: 15px;
    position: relative;
    i.drowdowArrow {
      margin-left: 0.5rem;
      font-size: 12px;
      transition-duration: 0.3s;
      transition-property: transform;
      border: none;
    }  
  }
  &:hover {
    i.drowdowArrow {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }
  .separator {
    opacity: 0.88;
  }
  .openMenu {
    position: absolute;
    padding-top: 15px;
  }
`;

const getColorPattern = currentPath => {
  if (['/flex'].includes(currentPath)) {
    return 'flex';
  } else if (['/borrow'].includes(currentPath)) {
    return 'borrow';
  } else if (['/invest', '/transfer', '/transfer-crypto', '/referral'].includes(currentPath)) {
    return 'invest';
  } else if (['/pro-lending'].includes(currentPath)) {
    return 'pro-lending';
  } else if (['/accounts'].includes(currentPath)) {
    return 'accounts';
  }

  return '';
};

class Menu extends PureComponent{
  state = {
    showMenu: false
  };

  handleMouseEnter = () => {
    const showed = true;
    const { onShow, isModal } = this.props;
    this.setState({showMenu: showed});
    if(onShow) {
      onShow(showed);
    }
    isModal && modalMenuOn();
  };

  handleMouseLeave = () => {
    const showed = false;
    const { onShow, isModal } = this.props;
    this.setState({showMenu: showed});
    if(onShow) {
      onShow(showed);
    }
    isModal && modalMenuOff();
  };

  render() {
    const { title, hideArrow = false, titleClassName, openClassName, children} = this.props;
    const { scrolled, location, isPro, isDark } = this.props;
    const { showMenu } = this.state;
    const hoverMenuPattern = !scrolled ? getColorPattern(location.pathname) : '';

    return (
      <ExtraMenuStyle isDark={isDark} isPro={isPro}>
        <div className={cx('menu', titleClassName, hoverMenuPattern)} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          {title}
          {!hideArrow && <i className="fas fa-caret-down drowdowArrow"/>}
        </div>
        {showMenu && (
          <div className={cx('openMenu', openClassName)} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
            {children}
          </div>
        )}
      </ExtraMenuStyle>
    );
  }
}

export default withRouter(Menu);
