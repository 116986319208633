import React from 'react';
import useCryptoInterest from 'src/hooks/useCryptoInterest';

const withCryptoInterest = (Component) => {
    const hocCryptoInterest = props => {
        const cryptoInterestProps = useCryptoInterest({});
        return <Component {...props} cryptoInterestProps={cryptoInterestProps} />;
    };
    return hocCryptoInterest;
}

export default withCryptoInterest;