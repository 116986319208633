import React from 'react';
import {URL} from 'src/resources/constants/url';
import {LabelLang} from 'src/lang/components';
import { BREADCRUM_NAME } from 'src/components/breadcrumRichSnippet/data';

const PATHS = [
  {
    title: BREADCRUM_NAME[URL.FLEX],
    url: URL.FLEX,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.INVEST],
    url: URL.INVEST,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.FLEX_CRYPTO],
    url: URL.FLEX_CRYPTO,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CRYPTO_LEND_SIGN_UP],
    url: URL.CRYPTO_LEND_SIGN_UP,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CRYPTO_LOAN],
    url: URL.CRYPTO_LOAN,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.SHORT_SIMPLE],
    url: URL.SHORT_SIMPLE,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.SHORT_SIMPLE_TRIAL],
    url: URL.SHORT_SIMPLE_TRIAL,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CRYPTO_SHORT],
    url: URL.CRYPTO_SHORT,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CRYPTO_SHORT_TRIAL],
    url: URL.CRYPTO_SHORT_TRIAL,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CRYPTO_FUTURE],
    url: URL.CRYPTO_FUTURE,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CRYPTO_FUTURE_TRIAL],
    url: URL.CRYPTO_FUTURE_TRIAL,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.DEPOSIT_FIAT],
    url: URL.DEPOSIT_FIAT,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.DEPOSIT_CRYPTO],
    url: URL.DEPOSIT_CRYPTO,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.DEPOSIT],
    url: URL.DEPOSIT,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.TRANSFER],
    url: URL.TRANSFER,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.TRANSFER_CRYPTO],
    url: URL.TRANSFER_CRYPTO,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.WITHDRAW_FEE],
    url: URL.WITHDRAW_FEE,
    parent: URL.HOME,
  },
  {
    title: 'Withdraw',
    url: URL.WITHDRAW_CRYPTO_DETAIL,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.COLLATERAL_BALANCE],
    url: URL.COLLATERAL_BALANCE,
    parent: URL.HOME,
  },
  // {
  //   title: BREADCRUM_NAME[URL.ABOUT_US],
  //   url: URL.ABOUT_US,
  //   parent: URL.HOME,
  // },
  {
    title: BREADCRUM_NAME[URL.TESTIMONIALS],
    url: URL.TESTIMONIALS,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.MEMBERSHIP],
    url: URL.MEMBERSHIP,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.REFERRAL],
    url: URL.REFERRAL,
    parent: URL.HOME,
    // group: <LabelLang id="headerBar.about" />,
    // groupUrl: null,
  },
  {
    title: BREADCRUM_NAME[URL.AFFILIATES],
    url: URL.AFFILIATES,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.FEE_AND_SERVICES_TIMES],
    url: URL.FEE_AND_SERVICES_TIMES,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.FEE_AND_SERVICES_TIMES],
    url: URL.FEE_AND_SERVICES_TIMES,
    parent: URL.HOME,
  },
  // {
  //   title: BREADCRUM_NAME[URL.STATISTICS],
  //   url: URL.STATISTICS,
  //   parent: URL.HOME,
  // },
  {
    title: BREADCRUM_NAME[URL.SECONDARY_MARKET],
    url: URL.SECONDARY_MARKET,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.MCT_STAKING],
    url: URL.MCT_STAKING,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.BUY_MCT],
    url: URL.BUY_MCT,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.MCT_MASCOT],
    url: URL.MCT_MASCOT,
    parent: URL.HOME,
  },
  {
    title: 'Result',
    url: URL.LENDING_LOTTERY_LANDING,
    parent: URL.LENDING_LOTTERY,
    // group: <LabelLang id="headerBar.extraMenu.invest.title" />,
    // groupUrl: null,
  },

  {
    title: 'Lending Lottery',
    url: URL.LENDING_LOTTERY,
    parent: URL.HOME,
    group: <LabelLang id="headerBar.extraMenu.invest.title" />,
    groupUrl: null,
  },
  // {
  //   title: <LabelLang id="footer.securityAndRisk" />,
  //   url: URL.LO_SECURITY_RISK,
  //   parent: URL.HOME,
  //   group: <LabelLang id="headerBar.about"/>,
  //   groupUrl: null,
  // },
  {
    title: <LabelLang id="notification.title" />,
    url: URL.NOTIFICATIONS,
    parent: URL.HOME,
  },
  {
    title: 'Me',
    url: URL.ME,
    parent: URL.ACCOUNTS,
  },
  {
    title: <LabelLang id="loanOriginators.loanRecords.loanDisbursement" />,
    url: URL.LOAN_DISBURSEMENT_DETAIL,
    parent: URL.ACCOUNTS,
    group: <LabelLang id="loanOriginators.loanRecords.heading" />,
    groupUrl: null,
  },
  {
    title: <LabelLang id="loanOriginators.loanRecords.loanPayment" />,
    url: URL.LOAN_PAYMENT_DETAIL,
    parent: URL.ACCOUNTS,
    group: <LabelLang id="loanOriginators.loanRecords.heading" />,
    groupUrl: null,
  },
  {
    title: BREADCRUM_NAME[URL.BADGES],
    url: URL.BADGES,
    parent: URL.HOME,
  },
  // {
  //   title: BREADCRUM_NAME[URL.HELP_AND_SUPPORT],
  //   url: URL.HELP_AND_SUPPORT,
  //   parent: URL.HOME,
  // },
  // {
  //   title: BREADCRUM_NAME[URL.CALL_US],
  //   url: URL.BOOK_A_CALL,
  //   parent: URL.HOME,
  // },
  {
    title: BREADCRUM_NAME[URL.GIFT_CARDS],
    url: URL.GIFT_CARDS,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.DEBIT_CARD],
    url: URL.DEBIT_CARD_LANDING,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CRYPTO_SWAP],
    url: URL.CRYPTO_SWAP,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.MCT_MASCOT],
    url: URL.MCT_MASCOT,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.MASCOT_NFT_DETAIL],
    url: URL.MASCOT_NFT_DETAIL,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.CONSTANT_TOKEN],
    url: URL.CONSTANT_TOKEN,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.AIRDROP_WHEEL],
    url: URL.AIRDROP_WHEEL,
    parent: URL.HOME,
  },
  {
    title: 'Loans Management',
    url: URL.ACCOUNTS_LOANS,
    parent: URL.HOME,
    group: <LabelLang id="headerBar.yourSaving" />,
    groupUrl: URL.ACCOUNTS,
  },
  {
    title: 'Investment',
    url: URL.ACCOUNTS_INVESTMENT,
    parent: URL.HOME,
    group: <LabelLang id="headerBar.yourSaving" />,
    groupUrl: URL.ACCOUNTS,
  },
  // fixed url
  {
    title: <LabelLang id="headerBar.yourSaving" />,
    url: URL.ACCOUNTS,
    parent: URL.HOME
  },
  {
    title: BREADCRUM_NAME[URL.LOAN_ORIGINATORS],
    url: URL.LOAN_ORIGINATORS,
    parent: URL.HOME,
  },
  {
    title: BREADCRUM_NAME[URL.WITHDRAW],
    url: URL.WITHDRAW,
    parent: URL.HOME,
  },
];

export default PATHS;
