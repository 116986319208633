import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { URL } from 'src/resources/constants/url';
import BlockSection from 'src/components/blocksection';
import StatisticWidget from 'src/components/statisticWidget';
import PATHS from './data';

const AppPathStyled = styled(BlockSection)`
  position: relative;
  z-index: 1;
  > div.container {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 10px;
    .paths {
      display: flex;
      height: fit-content;
      > a, div {
        font-size: 9px;
        color: #5690D4;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        min-width: 80px;
        text-align: center;
        padding: 2px 20px 2px 5px;
        font-weight: 500;
        
        i {
          margin: 0 10px;
        }
        
        &:last-child {
          color: #D4E8FF;
        }
        
        &:first-child {
          i {
            display: none;
          }
        }
      }
    }
    
    .statistic {
      margin-right: 40px;
    }
       
  }
`;

const getFullPaths = (url) => {
  const result = [];
  const p = PATHS.find(pp => url.includes(pp.url));
  if(p) {
    result.unshift(p);

    if(p.parent) {
      const parentPath = getFullPaths(p.parent);
      result.unshift(...parentPath);
    }
  }

  return result;
};

const HOME_PATH = [
  {
    title: 'Home',
    url: URL.HOME,
    parent: null
  },
];

const AppPath = props => {
  const { location } = props;
  const paths = getFullPaths(location.pathname);

  return paths?.length > 0 ? (
    <AppPathStyled>
      <div className="paths">
        {
        HOME_PATH.concat(paths).map((path, i) => {
          return (
            <React.Fragment key={i}>
              {path.group && <Link to={path.groupUrl ? path.groupUrl : '#'} style={{cursor: path.groupUrl ? 'pointer' : 'unset'}}><i className="fas fa-angle-right" /> {path.group}</Link>}
              <Link to={path.url}><i className="fas fa-angle-right" /> {path.title}</Link>
            </React.Fragment>
          );
        })
      }
      </div>
      <StatisticWidget className="statistic" />
    </AppPathStyled>
  ): null;
};

export default compose(
  withRouter,
)(AppPath);
